.board {
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.row {
  flex: 33%;
  display: flex;
  flex-direction: row;
  margin: 5px;
}

.letter {
  flex: 33%;
  height: 100%;
  border: 1px solid grey;
  margin: 5px;
  display: grid;
  place-items: center;
  font-size: 30px;
  font-weight: bolder;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

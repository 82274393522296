.bg {
  position: fixed;
  width: 100%;
  height:100%;
  background: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.stats {
  width: 28rem;
  height: 36em;
  background-color: #121212;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.statsTitle {
  font-size: 2rem;
}

.statsRow {
  display: flex;
  justify-content: space-evenly;
  margin: 20px;
}

.statItems {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
}

.statItemValue {
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.visuals {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
}

.guessView {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;
  width: 75%;
}

.guessRow {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.guessTop {
  border-bottom: 1px solid white;
  padding-bottom: 0.5rem;
}

.guessBottom {
  border-top: 1px solid white;
  padding-top: 0.5rem;
}

.chart {
  width: 50%;
}

.statItemName {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.guessRow {
  display: flex;
}

.guessRow > h1 {
  font-size: 16px;
  margin: 0;
}
